import ReactPixel from 'react-facebook-pixel';

export const mainLinks = [
  {
    title: 'Über Malbuner',
    submenu: [
      {
        link: '/ueber-malbuner',
        title: 'Vision',
      },
      {
        link: '/malbuner-geschichte',
        title: 'Geschichte',
      },
      {
        title: 'Nachhaltigkeit',
        link: '/nachhaltigkeit',
      },
      {
        link: '/tv-spots',
        title: 'TV Spots',
      },
      {
        link: '/laeden-und-aktionen',
        title: 'Läden & Aktionen',
      },
    ],
  },
  {
    title: 'News',
    submenu: [
      {
        link: '/social-media',
        title: 'Social Media',
      },
      {
        link: '/sponsorings',
        title: 'Sponsorings',
      },
      {
        link: '/spotify',
        title: 'Spotify',
      },
      {
        link: '/auszeichnungen',
        title: 'Auszeichnungen',
      },
    ],
  },
  {
    title: 'Sortiment',
    submenu: [
      {
        title: 'Charcuterie',
        link: '/charcuterie/sortiment',
      },
      // {
      //   title: 'Snacks',
      //   submenu: [
      //     {
      //       title: 'Snacks Sortiment',
      //       link: '/snacks/sortiment',
      //     },
      //     {
      //       title: 'Vegan Sortiment',
      //       link: '/vegan',
      //     },
      //   ],
      // },
      {
        title: 'Snacks',
        link: '/snacks/sortiment',
      },
      {
        title: 'Vegan',
        link: '/vegan',
      },
      {
        title: 'Convenience',
        link: '/convenience/sortiment',
      },
    ],
  },
  {
    link: 'https://shop.ospelt.com/:lng:/pages/fresspaeckli',
    title: 'Fresspäckli',
  },
  {
    link: 'https://shop.ospelt.com/:lng:',
    title: 'Shop',
    onClick: () => {
      ReactPixel.trackCustom('ShopClick', true);
    },
  },
];

export const footerLinks = [
  [
    {
      title: 'Ueber Malbuner',
    },
    {
      link: '/ueber-malbuner',
      title: 'Wer wir sind',
    },
    {
      link: '/nachhaltigkeit',
      title: 'Nachhaltigkeit',
    },
    {
      link: 'https://www.ospelt.com/karriere/',
      title: 'Karriere',
      target: '_blank',
    },
    {
      link: '/sponsorings',
      title: 'Sponsorings',
    },
  ],
  [
    {
      title: 'News',
    },
    {
      link: '/kontakt',
      title: 'Kontakt & Standort',
    },
    {
      link: '/faq',
      title: 'FAQ',
    },
  ],
  [
    {
      title: '‎',
    },
    {
      title: 'Impressum',
      link: '/impressum',
    },
    {
      title: 'Datenschutz',
      link: '/datenschutz',
    },
  ],
];
